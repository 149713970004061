import React, { useContext } from "react";
import Icon from "../Icon";
import sidebarRoutes from "../../routes/sidebar";
import { NavLink } from "react-router-dom";
import SidebarSubmenu from "./SidebarSubmenu";
import { AuthContext } from "../../context/AuthContext";

const SidebarContent = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="py-4 text-white">
      <a
        className="ml-6 text-xl font-bold uppercase text-nicegreen"
        href="/app/warehouse"
      >
        Achieva
      </a>
      <p className="ml-6 text-lg font-bold text-white">
        Smart Operations Management Portal
      </p>
      <ul className="mt-6">
        {sidebarRoutes
          .filter((i) => {
            if (user) {
              return user.Role.permissions.includes(i.resource);
            }
            return true;
          })
          .map((route) =>
            route.routes ? (
              <SidebarSubmenu route={route} key={route.name} />
            ) : (
              <li className="relative px-6 py-3" key={route.name}>
                <NavLink
                  to={`/app/${route.path}`}
                  className={({ isActive }) =>
                    `${
                      isActive ? `text-nicegreen font-semibold` : `text-white`
                    } inline-flex items-center w-full text-sm transition-colors duration-150 hover:text-nicegreen`
                  }
                >
                  <Icon
                    className="w-5 h-5"
                    aria-hidden="true"
                    icon={route.icon}
                  />
                  <span className="ml-4">{route.name}</span>
                </NavLink>
              </li>
            )
          )}
      </ul>
      {/* <div className="px-6 my-6">
        <Button>
          Create account
          <span className="ml-2" aria-hidden="true">
            +
          </span>
        </Button>
      </div> */}
    </div>
  );
};

export default SidebarContent;
